'use strict';

function servicesWidget() {
  var $servicesWrap = $('.services'),
      $service = $servicesWrap.find('.services__block'),
      $toggleBtn = $servicesWrap.siblings('.services__toggle').find('#js-services-button'),
      $hiddenServices = $servicesWrap.find('#js-more-services'),
      $hiddenService = $hiddenServices.find('.grid__cell'),
      $hiddenServiceCount = $hiddenService.length,
      hiddenServicesHeight;
  if (!$servicesWrap.length) return; // Reset height of hidden elements on resize

  var getHeightOnResize = debounce(function () {
    // Get height of hidden elements
    hiddenServicesHeight = $hiddenServices.get(0).scrollHeight;
    setHeight();
  }, 250);
  window.addEventListener('resize', getHeightOnResize);

  function setHeight() {
    $('#js-more-services.is-active').css({
      'max-height': hiddenServicesHeight
    });
  }

  ;

  function setButtonInnerHTML(button, innerHTML) {
    button.html(innerHTML);
  }

  $toggleBtn.on('click', function (e) {
    var $this = $(this);
    var buttonInnerHTML = {
      "default": $this.html()
    };
    buttonInnerHTML.showLess = buttonInnerHTML["default"].replace("more", "less");
    buttonInnerHTML.showMore = buttonInnerHTML["default"].replace("less", "more");

    if ($hiddenServices.attr('aria-hidden') === 'true') {
      setTimeout(function () {
        // Get height of hidden elements
        hiddenServicesHeight = $hiddenServices.get(0).scrollHeight;
        $toggleBtn.addClass('is-open');
        $toggleBtn.removeClass('is-shut');
        $hiddenServices.addClass('is-active');
        $hiddenServices.removeClass('is-hidden');
        setHeight();
      }, 50);
    } else {
      $('#js-more-services.is-active').css({
        'max-height': '0'
      });
      setTimeout(function () {
        $toggleBtn.removeClass('is-open');
        $toggleBtn.addClass('is-shut');
        $hiddenServices.removeClass('is-active');
        $hiddenServices.addClass('is-hidden');
      }, 500);
    }

    if ($this.attr('aria-expanded') === 'false') {
      setTimeout(function () {
        $('#js-more-services .services__block').first().focus(); // Show less

        setButtonInnerHTML($this, buttonInnerHTML.showLess);
      }, 20);
    } else {
      setTimeout(function () {
        $this.focus(); // Show more

        setButtonInnerHTML($this, buttonInnerHTML.showMore);
      }, 20);
    }
  });
  $service.matchHeight({
    byRow: false
  });
}

;