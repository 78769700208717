"use strict";

/**
 * Lightbox effect for images
 *
 * @param {...string} - Containers for zoomable images
 *
 * https://www.npmjs.com/package/@nishanths/zoom.js
 * Supports IE10 and above.
 *
 * Forked from https://github.com/fat/zoom.js
 * Use the original instead if you need IE9 support (not on NPM) - Keep this init file the same
 *
 * Usage:
 * zoomImage('.widget--image', '.widget--top-tasks');
 */
var zoomImage = function zoomImage() {
  var args = [].slice.call(arguments),
      argsLength = args.length,
      selectors = "";

  for (var i = 0; i < argsLength; i++) {
    selectors += ", " + args[i];
  }

  var imgContainers = document.querySelectorAll('.editor' + selectors);

  var addZoom = function addZoom(image) {
    [].forEach.call(image, function (el) {
      if (el.parentNode.nodeName.toLowerCase() === 'a') {
        return;
      }

      el.setAttribute('data-action', 'zoom');
    });
  };

  [].forEach.call(imgContainers, function (container) {
    var zoomableImgs = container.querySelectorAll('img');
    addZoom(zoomableImgs);
  });
};