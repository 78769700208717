"use strict";

/**
 * External Link Check
 *
 * Check for extenal links within .editor and add a class.
 *
 * To ignore external links, add the following
 * data attribute to your markup:
 * [data-external-link="false"]
 */
(function () {
  'use strict';

  var links = document.querySelectorAll('.editor a');
  [].forEach.call(links, function (elem) {
    if (location.hostname === elem.hostname || !elem.hostname.length) return;
    if (elem.getAttribute('data-external-link') === 'false' || elem.classList.contains('button')) return;
    elem.classList.add('link-external');
  });
})();