'use strict';

function topTasksWidget() {
  var $topTasks = $('.js-top-tasks');
  if (!$topTasks.length) return;
  var $topTaskParents = $topTasks.find('.top-task-parent'),
      $topTaskContainer = $topTaskParents.parent(),
      $topTaskChild = $('.top-task-child__wrap'),
      hiddenChildHeight; // Reset height of hidden elements on resize

  var getHeightOnResize = debounce(function () {
    // Get height of hidden elements
    if (!$topTaskChild.hasClass('is-active')) return;
    hiddenChildHeight = $('.top-task-child__wrap.is-active').find('.top-task-child').get(0).scrollHeight;
    setHeight();
  }, 250);
  window.addEventListener('resize', getHeightOnResize);

  function setHeight() {
    $topTaskChild.css({
      'max-height': hiddenChildHeight
    });
  }

  ;
  $topTaskParents.on('click', function (e) {
    var $this = $(this),
        $thisParent = $this.parent(),
        $thisChild = $this.siblings('.top-task-child__wrap'),
        activeChildId;

    if ($thisParent.hasClass('is-active')) {
      $thisParent.removeClass('is-active');
    } else {
      $topTaskContainer.removeClass('is-active');
      $thisParent.addClass('is-active');
    }

    if ($thisChild.attr('aria-hidden') === 'true') {
      setTimeout(function () {
        hiddenChildHeight = $thisChild.get(0).scrollHeight;
        if ($topTaskChild == $thisChild) return;
        $topTaskChild.removeClass('is-active');
        $topTaskChild.addClass('is-hidden');
        $thisChild.addClass('is-active');
        $thisChild.removeClass('is-hidden');
        activeChildId = $thisChild.attr('id');
        $topTaskChild.css({
          'max-height': hiddenChildHeight
        });
        $('#' + activeChildId).css({
          'max-height': hiddenChildHeight
        });
      }, 100);
    } else {
      activeChildId = $thisChild.attr('id');
      $('#' + activeChildId).css({
        'max-height': '0'
      });
      setTimeout(function () {
        $topTaskChild.addClass('is-hidden');
        $topTaskChild.removeClass('is-active');
      }, 500);
    }
  });

  function checkIconLoaded($selector, interval) {
    if ($selector != null) {
      $topTaskParents.matchHeight();
    } else {
      setTimeout(function () {
        checkIconLoaded($selector, interval);
      }, interval);
    }
  }

  ;
  checkIconLoaded($('.top-task-parent__icon svg', 200));
}

;