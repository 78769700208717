'use strict';

var $widgetNavigation = $('.widget-row').find('.navigation-widget');

(function ($) {
  zoomImage(); //  PRINT PAGE
  // =======================

  var $printButton = document.getElementById('js-print');

  if ($printButton !== null) {
    $printButton.addEventListener('click', function (e) {
      e.preventDefault();
      window.print();
    });
  } // On document ready...


  document.addEventListener("DOMContentLoaded", function () {
    $('.button:not(.form__label), .form__label--upload, .form__label--image-upload').wrapInner('<span class="button__inner"></span>');
    initA11yToggle();
    navigationToggle();
    focusLoop($('.focus-loop'));
    topTasksWidget();
    servicesWidget();
    toggleMobileSwap(breakpoints.tablet);
    if (!$widgetNavigation) return;
    $widgetNavigation.matchHeight();
    $(".grid__cell > .boxed").matchHeight(); // Forms

    $(".form--block .form__field, .form--block .form__field + .button").matchHeight();
    responsiveTables.amendMarkUp();
    responsiveTables.check(); // temporary scroller for matrix table

    $('.form__matrix').each(function () {
      var table = $(this);
      $(this).wrap('<div class="table-wrapper-matrix"></div>').wrap('<div class="scroller"></div>');

      if (table.outerWidth() > table.parent().parent().outerWidth()) {
        table.parent().parent().addClass('has-scroll');
      }

      $(window).on('resize orientationchange', function () {
        if (table.outerWidth() > table.parent().parent().outerWidth()) {
          table.parent().parent().addClass('has-scroll');
        } else {
          table.parent().parent().removeClass('has-scroll');
        }
      });
    }); //  Re-focus to the last link in the menu on back-tab
    //  Close menu when esc key is pressed
    // ========================================================

    var $navigation = $(menu.navigation);
    var $menuClose = $(menu.toggle);
    var $navigationLinks = $navigation.find('a');
    var $navigationLastLink = $navigationLinks.last();
    var $searchClose = $(search.toggle);
    var $searchNav = $(search.navigation);
    var $searchBtn = $searchNav.find('.form__append-group .button');
    var $searchInput = $searchNav.find('.form__append-group .form__field');
    $body.on('keydown', function (event) {
      if ($menuClose.attr('aria-expanded') == 'true') {
        if (event.keyCode === 9 && $menuClose.is(':focus')) {
          if (event.shiftKey) {
            event.preventDefault();
            $navigationLastLink.focus();
          }
        }

        if (event.keyCode === 27) {
          $menuClose.attr('aria-expanded', 'false');
          $navigation.attr('aria-hidden', 'true');
          $menuClose.focus();
          setTimeout(function () {
            $navigation.removeClass('is-open');
          }, 200);
        }

        if (event.keyCode === 9 && $navigationLastLink.is(':focus') && !event.shiftKey) {
          event.preventDefault();
          $menuClose.focus();
        }
      }

      if ($searchClose.attr('aria-expanded') == 'true') {
        if (event.keyCode === 9 && $searchClose.is(':focus')) {
          if (event.shiftKey) {
            event.preventDefault();
            $searchBtn.focus();
          }
        }

        if (event.keyCode === 9 && $searchInput.is(':focus')) {
          if (event.shiftKey) {
            event.preventDefault();
            $searchClose.focus();
          }
        }

        if (event.keyCode === 27) {
          $searchNav.removeClass('is-active');
          $searchNav.attr('aria-hidden', 'true');
          $searchClose.attr('aria-expanded', 'false');
          $body.css('transform', '');
          $searchClose.focus();
          setTimeout(function () {
            $searchNav.removeClass('is-open');
            $body.removeClass('search-is-open');
          }, 200);
        }
      }
    });
  }); // On window load...

  window.addEventListener('load', function () {
    carouselInit('.js-carousel');
    skipMaps();
  }); // On window resize...

  window.addEventListener('resize', function () {
    toggleMobileSwap(pagesIn.navigation, pagesIn.toggle, breakpoints.tablet, true);
    responsiveTables.check();
  });
  /**
   * On orientation change
   */

  window.addEventListener('orientationchange', function () {
    responsiveTables.check();
  });
})(jQuery);