"use strict";

/**
 * Toggles elements between desktop and mobile
 *
 * Useful if elements need to be shown
 * on desktop and hidden on mobile
 *
 * Add on DOMContentLoaded and Resize
 *
 * @param {HTMLElement} elem   - The element
 * @param {HTMLElement} toggle - The toggle
 * @param {HTMLElement} breakpoint - integer viewport size value (set in globals.js - default 600px)
 * @param {HTMLElement} resize - boolean value to differentiate between first page load and on browser resize event (triggered from main.js).
 *
 * @return {function} A debounce function
 */
function toggleMobileSwap(elem, toggle, breakpoint) {
  var resize = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  if (!elem) return;
  if (!toggle) return;
  breakpoint = typeof breakpoint === 'undefined' ? breakpoints.mobile : breakpoint;
  jQuery.extend(verge);

  if (verge.viewportW() >= breakpoint) {
    elem.setAttribute('aria-hidden', 'false');
    elem.setAttribute('data-toggle-mobile-swap', 'above-' + breakpoint);
    [].forEach.call(toggle, function (button) {
      button.setAttribute('aria-expanded', 'true');
    });
  } else {
    if (resize === false) {
      elem.setAttribute('data-toggle-mobile-swap', 'below-' + breakpoint);
    }

    if (elem.getAttribute('data-toggle-mobile-swap') === 'above-' + breakpoint) {
      elem.setAttribute('aria-hidden', 'true');
      [].forEach.call(toggle, function (button) {
        button.setAttribute('aria-expanded', 'false');
      });
      elem.setAttribute('data-toggle-mobile-swap', 'below-' + breakpoint);
    }
  }

  return debounce(function () {
    toggleMobileSwap(elem, toggle, breakpoint);
  }, 100);
}

;