// Loop through focusable elements on navigation
// Add hidden element to end of list, pass as parameter to this function
// =====================================================================
'use strict';

function focusLoop(elem) {
  var focusTo = elem.siblings('.button');
  elem.on('focus', function () {
    focusTo.focus();
  });
}

;