"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

//  Siteimprove fixes for embedded maps
// ========================================================
function initMaps() {
  // ORBIT-130 - We will no longer try to fix Siteimprove errors on Google Maps
  // https://issuetracker.google.com/issues/69541792
  // Advice for clients to conform with WCAG 2.1 AA standard is to provide text alternatives for the information in the map.
  return;
}

function skipMaps() {
  if ((typeof skipEmbeddedMaps === "undefined" ? "undefined" : _typeof(skipEmbeddedMaps)) !== 'object') {
    return;
  }

  var $skipMapButton = $(skipEmbeddedMaps);
  var $allButtons = $siteMain.find('a, .button, input:not([type="hidden"]), select, textarea');
  $skipMapButton.focus(function () {
    $(this).removeClass('visually-hidden');
  });
  $skipMapButton.blur(function () {
    $(this).addClass('visually-hidden');
  });
  $allButtons.each(function (index, value) {
    var $this = $(this);
    if (!$this.hasClass('js-map-skip')) return;
    $this.on('click', function (event) {
      event.preventDefault();
      $allButtons[index + 1].focus();
    });
  });
}