'use strict'; //  CAROUSEL
// ==========

function pauseCurrentCarousel(el) {
  $(el).closest('.flickity-enabled').flickity('stopPlayer');
} // Add text to buttons


function flickityPaginationText(el) {
  el.find('.flickity-prev-next-button.next').append('<span class="visually-hidden">next</span>');
  el.find('.flickity-prev-next-button.previous').append('<span class="visually-hidden">previous</span>');
}

function carouselInit(carouselClass) {
  var $carousels = $(carouselClass),
      carouselCount = $carousels.length,
      carouselSlideClass = '.slide';

  if (carouselCount) {
    $carousels.each(function () {
      var $currentCarousel = $(this),
          autoPlaySpeed = $currentCarousel.attr('data-carousel-autoplay') * 1000,
          $videoPlaceholders = $currentCarousel.find('[data-video-oembed]'),
          carouselHasVideos = false;

      if ($videoPlaceholders.length) {
        carouselHasVideos = true;
        loadVideos($videoPlaceholders);
      }

      $currentCarousel.flickity({
        accessibility: false,
        autoPlay: autoPlaySpeed,
        cellSelector: carouselSlideClass,
        imagesLoaded: true,
        pageDots: true,
        prevNextButtons: true,
        wrapAround: true
      });
      var flktyStandard = $currentCarousel.data('flickity'),
          $slideLinks = $currentCarousel.find('a, iframe');

      if (flktyStandard.cells.length > 1) {
        flickityPaginationText($currentCarousel);
      } else {
        $currentCarousel.find('.flickity-prev-next-button, .flickity-page-dots').css('display', 'none');
      }

      $slideLinks.on('focus', function (event) {
        var $carouselCta = $(this),
            $targetSlide = $carouselCta.closest(carouselSlideClass);
        $currentCarousel.flickity('pausePlayer');

        if (!$targetSlide.hasClass('is-selected')) {
          var cellToSelect = $targetSlide.index() - 1;
          $focusReferencePoint.focus();
          $currentCarousel.flickity('select', cellToSelect, false, true);
          window.setTimeout(function () {
            $carouselCta.focus();
          }, 10);
        }
      });
      $slideLinks.first().on('keydown', function (event) {
        if (event.keyCode === 9) {
          if (event.shiftKey) {
            $flickitySlider.removeClass('has-focus');
          }
        }
      });
      $slideLinks.last().on('keydown', function (event) {
        if (event.keyCode === 9) {
          if (!event.shiftKey) {
            unpausePlayCarousel('play');
            $flickitySlider.removeClass('has-focus');
          }
        }
      });
      $slideLinks.on('blur', function (event) {
        $flickitySlider.removeClass('has-focus');
      });
    });
  }
}

;