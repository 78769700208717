// NAVIGATION TOGGLE & PAGE OVERLAYS
// =================================
'use strict';

function navigationToggle() {
  var $body = $('body'),
      $header = $('.site-header'),
      // Menu
  $navToggle = $header.find('#js-menu-toggle'),
      $menu = $('#js-menu'),
      $navToggleClose = $menu.find('#js-nav-toggle-close'),
      // Bookmarks
  $bookmarkToggle = $('#js-bookmarks-toggle'),
      $bookmarks = $('#js-bookmarks-navigation'),
      $bookmarkToggleClose = $bookmarks.find('#js-bookmark-toggle-close'),
      $menuItems = $menu.find('.list__item'),
      $bookmarkItems = $bookmarks.find('.list__item'),
      $visitedPagesMessageButton = $('.visited-pages__accept');
  var overlayDelay = 500;

  var createCookie = function createCookie(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = '; expires=' + date.toGMTString();
    } else {
      var expires = '';
    }

    document.cookie = name + '=' + value + expires + '; path=/;secure';
  };

  var animateMenu = function animateMenu(elem) {
    if (elem.attr('aria-hidden') === 'true') {
      setTimeout(function () {
        elem.addClass('is-open');
        elem.removeClass('is-hidden');
      }, 50);
    } else {
      setTimeout(function () {
        elem.addClass('is-hidden');
        elem.removeClass('is-open');
      }, overlayDelay);
    }
  };

  var animateMenuItems = function animateMenuItems(i, elem, action, delay) {
    setTimeout(function () {
      if (action === 'add') elem.eq(i).addClass('is-animated');else elem.eq(i).removeClass('is-animated');
    }, delay * i);
  };

  var toggleMenuButton = function toggleMenuButton() {
    if ($navToggle == null) return;
    animateMenu($menu);
    $navToggle.off('click', false); // Reset focus

    setTimeout(function () {
      $navToggleClose.focus();
    }, overlayDelay); // If menu closed

    if (this.id == $navToggleClose.attr('id')) {
      for (var i = 0, len = $menuItems.length; i < len; i++) {
        animateMenuItems(i, $menuItems, 'remove', 35);
      } // Reset focus


      setTimeout(function () {
        $navToggle.focus();
      }, 20);
    } else {
      for (var i = 0, len = $menuItems.length; i < len; i++) {
        animateMenuItems(i, $menuItems, 'add', 35);
      }
    } // Close bookmarks if main menu opened


    if ($bookmarks.attr('aria-hidden') == 'true') {
      return;
      $bookmarkToggleClose.trigger('click');
    } // ==============================
    //

  };

  var toggleBookmarkButton = function toggleBookmarkButton() {
    if ($bookmarkToggle == null) return;
    $visitedPagesMessageButton.on('click', function (event) {
      event.preventDefault();
      createCookie('acceptVisitedPagesMessage', 1, 365);
    });
    animateMenu($bookmarks);
    $bookmarkToggle.off('click', false); // Reset focus

    setTimeout(function () {
      $bookmarkToggleClose.focus();
    }, overlayDelay);

    if (this.id === $bookmarkToggleClose.attr('id')) {
      for (var i = 0, len = $bookmarkItems.length; i < len; i++) {
        animateMenuItems(i, $bookmarkItems, 'remove', 45);
      }

      setTimeout(function () {
        $bookmarkToggle.focus();
      }, 20);
    } else {
      for (var i = 0, len = $bookmarkItems.length; i < len; i++) {
        animateMenuItems(i, $bookmarkItems, 'add', 45);
      }
    } // Close main menu if bookmarks opened


    if ($menu.attr('aria-hidden') == 'true') {
      return;
      $navToggleClose.trigger('click');
    } // ===================================
    //

  };

  var clickOutside = function clickOutside(elem, button) {
    elem.on('clickoutside', function () {
      if (elem.hasClass('is-open')) {
        button.trigger('click');
      }
    });
  };

  $navToggle.on('click', toggleMenuButton);
  $navToggleClose.on('click', toggleMenuButton);
  $bookmarkToggle.on('click', toggleBookmarkButton);
  $bookmarkToggleClose.on('click', toggleBookmarkButton);
  clickOutside($menu, $navToggleClose);
  clickOutside($bookmarks, $bookmarkToggleClose);
}

;